<template>
  <small-layout
    ><div>
      <h1>Update</h1>
      <SupplierViewAttractionCrud
        v-bind:country="country"
        v-bind:supplier_id="$route.params.supplier"
        v-bind:supplier_appendix="supplier.appendix"
        @require-rule="requireRulesForSubscriptionsLevels"
      ></SupplierViewAttractionCrud>
      <form ref="supplierUpdateFormRef" @submit.prevent="fireUpdatingSupplier">
        <fieldset>
          <legend>информация об Спортивном Объекте для опубликования</legend>
          <div>
            <label for="supplier_name">Название:</label>
            <input
              id="supplier_name"
              style="width: 100%"
              type="text"
              maxlength="191"
              pattern="[^%\/]+"
              :title="$t('Symbols % and / can cause issues with the QR code image generator.')"
              v-model="supplier.name"
              required
            />
          </div>
          <div>
            <label for="general_information">О нас (для клиента)</label>
            <wysiwyg
              v-model="supplier.general_information"
              v-bind:class="wysiwygClass"
              v-bind:title="$t('The field is required and must contain at least 26 characters.')"
            ></wysiwyg>
          </div>
          <div v-for="subscription in Object.values(config.subscriptions_acronyms)">
            <label :for="'rules_' + subscription">Rules {{ subscription }}</label>
            <textarea
              :ref="`rules_${subscription}_${levelRulesRerenderKey}`"
              rows="4"
              cols="50"
              v-model="supplier.rules[subscription]"
              :id="'rules_' + subscription"
              :required="levelsWithRequiredRules[subscription]"
            ></textarea>
          </div>
          <div>
            <label for="city">City</label>
            <select v-model="supplier.city" id="city">
              <option value=""></option>
              <option v-for="city in config.cities" :value="city" :key="city">{{ city }}</option>
            </select>
          </div>
          <div>
            <label for="address">Адрес</label>
            <textarea rows="4" cols="50" v-model="supplier.address" id="address" required></textarea>
          </div>
          <div>
            <label for="update_lat_lng">На карте</label>
            <YandexMap
              v-if="map.link"
              id="update_lat_lng"
              v-bind:link="map.link"
              v-bind:lat="map.lat"
              v-bind:lng="map.lng"
              v-on:update_coordinates="updateCoordinates"
            />
          </div>
          <div>
            <label for="contact_phone">Phones for Holders</label>

            <ul id="contact_phone_block" v-if="config">
              <li>
                <phone-input
                  id="contact_phone"
                  :value="contact_phone_one"
                  :pattern="config.phone_input_regex"
                  @change="addPhone"
                ></phone-input>
              </li>
              <li v-for="(item, key) in contact_phone_list">
                {{ phone_validate(item) }}
                <span><button @click.prevent="removeItemByKey(contact_phone_list, key)">X</button></span>
              </li>
            </ul>
          </div>
          <div>
            <label for="website">Website</label>
            <input
              type="text"
              inputmode="url"
              pattern="https?://.*"
              maxlength="191"
              style="width: 100%"
              v-model="supplier.website"
              id="website"
            />
          </div>
          <div>
            <label for="client_rules">Rules</label>
            <input
              type="text"
              inputmode="url"
              pattern="https?://.*"
              maxlength="191"
              style="width: 100%"
              v-model="supplier.client_rules"
              id="client_rules"
            />
          </div>
          <div>
            <label for="opening_hours_text">Schedule</label>
            <ul id="opening_hours_text">
              <li>
                <label for="schedule_url">Path to schedule</label>
                <input
                  type="text"
                  inputmode="url"
                  pattern="https?://.*"
                  maxlength="191"
                  style="width: 100%"
                  v-model="schedule.url"
                  id="schedule_url"
                />
                <label for="schedule_message">Warning about schedule</label>
                <input
                  type="text"
                  inputmode="text"
                  maxlength="191"
                  style="width: 100%"
                  v-model="schedule.message"
                  id="schedule_message"
                />
                <label for="schedule_last_changed_at">Last sync</label>
                <input type="datetime-local" v-model="schedule.last_changed_at" id="schedule_last_changed_at" />
              </li>
              <li>
                <label for="new_opening_hours_item">New period</label>
                <select id="new_opening_hours_item" ref="new_opening_hours_item">
                  <option value="mon">Пн</option>
                  <option value="tue">Вт</option>
                  <option value="wed">Ср</option>
                  <option value="thu">Чт</option>
                  <option value="fri">Пт</option>
                  <option value="sat">Сб</option>
                  <option value="sun">Вс</option>
                </select>
                <button @click.prevent="addHourRange">Add</button>
              </li>
              <li>
                Пн
                <span v-for="(item, key) in schedule.mon">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.mon, key)">X</button></span>
                </span>
              </li>
              <li>
                Вт
                <span v-for="(item, key) in schedule.tue">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.tue, key)">X</button></span>
                </span>
              </li>
              <li>
                Ср
                <span v-for="(item, key) in schedule.wed">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.wed, key)">X</button></span>
                </span>
              </li>
              <li>
                Чт
                <span v-for="(item, key) in schedule.thu">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.thu, key)">X</button></span>
                </span>
              </li>
              <li>
                Пт
                <span v-for="(item, key) in schedule.fri">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.fri, key)">X</button></span>
                </span>
              </li>
              <li>
                Сб
                <span v-for="(item, key) in schedule.sat">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.sat, key)">X</button></span>
                </span>
              </li>
              <li>
                Вс
                <span v-for="(item, key) in schedule.sun">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.sun, key)">X</button></span>
                </span>
              </li>
            </ul>
          </div>
          <div>
            <label>Теги (для карты)</label>
            <div v-for="(title, index) in tag_info_array">
              <label :for="'supplier_tags_' + index">{{ title }}</label>
              <input
                type="checkbox"
                :id="'supplier_tags_' + index"
                v-bind:value="title"
                v-model="supplier.tags_array"
              />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Details Act of services</legend>
          <div>
            <label for="unp">{{ $t("UNP (Payer Registration Number)") }}</label>
            <input type="number" v-model="supplier.unp" id="unp" />
            {{ supplier.registered_name }}
          </div>
          <div v-if="supplier.third_party_validation === turned_off">
            <label for="legal_name">Legal Name</label>
            <input type="text" v-model="supplier.registered_name" id="legal_name" />
          </div>
          <div>
            <label for="number_agreement">Номер и дата соглашения</label>
            <input
              id="number_agreement"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.number_agreement"
            />
          </div>
          <div>
            <label for="number_agreement_super">Номер и дата соглашения (SUPER)</label>
            <input
              id="number_agreement_super"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.number_agreement_super"
            />
          </div>
          <div>
            <label for="legal_address">Benefiiary Legal Address</label>
            <input
              id="legal_address"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.legal_address"
            />
          </div>
          <div>
            <label for="aemail">Benefiiary Accountant Email</label>
            <input
              id="aemail"
              style="width: 100%"
              type="email"
              inputmode="email"
              multiple="multiple"
              maxlength="191"
              v-model="supplier.aemail"
            />
          </div>
          <div>
            <label for="postaddress">Benefiiary Post Address</label>
            <input id="postaddress" style="width: 100%" type="text" maxlength="191" v-model="supplier.postaddress" />
          </div>
          <div>
            <label for="legal_contact_phone">Benefiiary Legal Contact Phone</label>
            <phone-input
              id="legal_contact_phone"
              style="width: 100%"
              :value="supplier.legal_contact_phone"
              @change="supplier.legal_contact_phone = arguments[0]"
              :pattern="config.phone_input_regex"
            ></phone-input>
          </div>
          <div>
            <label for="bank_name">Benefiiary Bank</label>
            <input id="bank_name" style="width: 100%" type="text" maxlength="75" v-model="supplier.bank_name" />
          </div>
          <div>
            <label for="bik">Benefiiary BIK</label>
            <input id="bik" style="width: 100%" type="text" maxlength="11" v-model="supplier.bik" />
          </div>
          <div>
            <label for="accnum">Benefiiary Account</label>
            <input id="accnum" style="width: 100%" type="text" maxlength="75" v-model="supplier.accnum" />
          </div>
          <div>
            <label for="vat_rate">VAT rate</label>
            <select id="vat_rate" v-model="supplier.vat_rate">
              <option v-for="(value, name) in config.vat_rate" :key="name" :value="value">{{ name }}</option>
            </select>
          </div>
          <div>
            <label for="sellStrategy">Sell strategy</label>
            <select v-model="supplier.sell_strategy" id="sellStrategy">
              <option v-for="option in sellStrategyOptions" :value="option.value" :key="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
          <div v-for="(value, name) in config.payment_fields">
            <label :for="name">{{ $t(`Title ${name}`) }} {{ supplier.payment_details[name] }}</label>
            <input :id="name" v-model="supplier.payment_details[name]" style="width: 100%" type="text" maxlength="75" />
          </div>
        </fieldset>
        <fieldset>
          <legend>Other info in agreement</legend>
          <div>
            <label for="appendix">Номер и дата приложения 1</label>
            <input id="appendix" style="width: 100%" type="text" maxlength="191" v-model="supplier.appendix" />
          </div>
          <div>
            <label for="official_representative">От лица кого подписано? от лица "директора/руководителя/..."</label>
            <input
              id="official_representative"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.official_representative"
            />
          </div>
          <div>
            <label for="power_of_attorney_representative">на каком Основании Исполнитель заключает договор</label>
            <input
              id="power_of_attorney_representative"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.power_of_attorney_representative"
            />
          </div>
          <div>
            <label for="hardware_device">Переданное устройство</label>
            <input
              id="hardware_device"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.hardware_device"
            />
          </div>
          <div>
            <label for="hardware_device_number">Номер телефона устройства (*147#)</label>
            <phone-input
              id="hardware_device_number"
              :value="supplier.hardware_device_number"
              @change="supplier.hardware_device_number = arguments[0]"
              :pattern="config.phone_input_regex"
            ></phone-input>
          </div>
        </fieldset>
        <fieldset>
          <legend>важные данные и административная информация</legend>
          <div>
            <label for="administrative_info">Contacts (Every line: Position, Name, email/phone)</label>
            <textarea
              rows="4"
              cols="50"
              v-model="supplier.administrative_info"
              id="administrative_info"
              required
            ></textarea>
          </div>
          <div>
            <label for="negotiation">Согласованные моменты, особенность договора с ними, помимо цен</label>
            <textarea rows="4" cols="50" v-model="supplier.negotiation" id="negotiation"></textarea>
          </div>
          <div>
            <label for="partner_status">Текущий статус партнера</label>
            <textarea rows="4" cols="50" v-model="supplier.partner_status" id="partner_status"></textarea>
          </div>
          <div>
            <label for="reason_outdated">Какую информацию надо добавить</label>
            <textarea rows="4" cols="50" v-model="supplier.reason_outdated" id="reason_outdated"></textarea>
          </div>
          <div>
            <label for="allsports_contact">кто договаривался</label>
            <textarea rows="4" cols="50" v-model="supplier.allsports_contact" id="allsports_contact"></textarea>
          </div>
          <div>
            <label for="support_phone">Поддержка зала:</label>
            <input list="support_phonees" v-model="supplier.support_phone" id="support_phone" />
            <datalist id="support_phonees">
              <option :value="config.support_phone">Телефон по умолчанию</option>
            </datalist>
          </div>
          <div>
            <label for="administration_status">Актуальность Информации</label>
            <input list="administration_statuses" v-model="supplier.administration_status" id="administration_status" />
            <datalist id="administration_statuses">
              <option value="Договор на подписании" />
              <option value="Цены на согласовании" />
              <option value="Требуется установить устройство" />
              <option value="Контрольная закупка" />
              <option value="Данные требуется обновить" />
              <option value="Правила на заверении" />
              <option value="Акт приема передачи на подписании" />
              <option value="Требуется забрать документы" />
              <option value="Требуется записать телефон переданного устройства" />
            </datalist>
          </div>
          <div>
            <label for="access_mode">Access Mode (standard, ticket like GymExpress, location required)</label>
            <select v-model="supplier.access_mode" id="access_mode">
              <option value="standard">Standard</option>
              <option value="ticket">Ticket</option>
              <option value="location">Location</option>
              <option value="akova">Akova</option>
            </select>
          </div>
          <div>
            <label for="scanning_status"></label>
            <select v-model="supplier.scanning_status" id="scanning_status">
              <option value="0">Незаполненный</option>
              <option value="1">Заполненный</option>
              <option value="15">Онлайн</option>
              <option value="7">Онлайн (QR only - should not be by default)</option>
              <option value="11">Онлайн (NFC only - QR OFF)</option>
              <option value="5">Офлайн (QR only - should not be by default)</option>
              <option value="13">Офлайн (NFC only - QR OFF)</option>
            </select>
          </div>
          <div>
            <label for="third_party_validation">Turn off validation UNN/IBAN</label>
            <input
              type="checkbox"
              id="third_party_validation"
              v-model="supplier.third_party_validation"
              :true-value="turned_off"
            />
          </div>
          <div>
            <label for="visit_autoaccept">Auto-confirmation of visits (for facilities without administrators)</label>
            <input
              type="checkbox"
              id="visit_autoaccept"
              v-model="supplier.visit_autoaccept"
            />
          </div>
          <br>
          <div>
            <button>Сохранить</button>
            <button @click.prevent="fireCloningSupplier">Create Duplicate</button>
          </div>
        </fieldset>
      </form>
      <Modal>
        <validation-errors :errors="validationErrors"></validation-errors>
      </Modal>
    </div>
  </small-layout>
</template>

<script>
import ValidationErrors from "../../ValidationErrors";
import PhoneInput from "../../PhoneInput";
import YandexMap from "../../YandexMap.vue";
import PdfReader from "../../PdfReader";
import SupplierViewAttractionCrud from "../SupplierViewAttractionCrud";
import Vue from "vue";
import config from "../../../config";
import { mapActions, mapGetters } from "vuex";
import { ref } from "@vue/composition-api";
import cloneDeep from "lodash/cloneDeep";
import { MODAL_TYPES } from "@/store/modules/modal";
import Modal from "@/components/modal/Modal.vue";
import prepareAxiosErrors from "@/helpers/prepareAxiosErrors";

const yandex_link = process.env.VUE_APP_YANDEX_LINK;
const turned_off = "off";

export default {
  components: {
    SupplierViewAttractionCrud,
    ValidationErrors,
    YandexMap,
    PdfReader,
    PhoneInput,
    Modal,
  },
  data() {
    const supplierUpdateFormRef = ref(null);
    const rules = {};
    const country = "by";
    const localConfig = config.supported_countries[country];
    Object.values(localConfig.subscriptions_acronyms).map((subscription) => {
      if (!rules[subscription]) rules[subscription] = "";
    });

    return {
      turned_off,
      levelRulesRerenderKey: 0,
      levelsWithRequiredRules: {},
      supplierUpdateFormRef,
      contact_phone_one: localConfig.phone_prefix,
      map: {
        link: undefined,
        lat: undefined,
        lng: undefined,
      },
      config: localConfig,
      country,
      tag_info_array: [
        "Бесплатная парковка",
        "Доплата на объекте",
        "Круглосуточный",
        "Только для женщин",
        "Услуги для родителей с детьми или беременных женщин",
        "Сезонное обслуживание",
        "Wi-Fi",
        "Требуется бронирование",
      ],
      supplier: {
        aggregator: localConfig.aggregator,
        country,
        city: localConfig.cities[0],
        tags_array: [],
        vat_rate: "no_vat",
        sell_strategy: "",
        payment_details: Vue.observable({}),
        rules,
        general_information: "",
        additional: "",
        third_party_validation: localConfig.unn_validation_url ? "" : turned_off,
        visit_autoaccept: false,
      },
      validationErrors: "",
      contact_phone_list: [],
      schedule: {
        mon: [["09:00", "18:00"]],
        tue: [["09:00", "18:00"]],
        wed: [["09:00", "18:00"]],
        thu: [["09:00", "18:00"]],
        fri: [["09:00", "18:00"]],
        sat: [["09:00", "18:00"]],
        sun: [["09:00", "18:00"]],
        url: null,
        last_changed_at: null,
      },
    };
  },
  computed: {
    sellStrategyOptions: function () {
      const optionsList = this.countryConfig()?.sellers.map(({ code, name }) => ({ value: code, text: name }));
      const supplementedOptionsList = [{ value: "country", text: "Country" }, ...optionsList];

      return supplementedOptionsList;
    },
    wysiwygClass: function () {
      return {
        requiredFieldError: this.isWysiwygElementEmpty,
      };
    },
    isWysiwygElementEmpty: function () {
      return (
        !this.supplier.general_information || this.clearStringFromHtml(this.supplier.general_information).length < 26
      );
    },
  },
  async created() {
    const supplier = await this.getSupplier(this.$route.params.supplier);
    if (supplier.contact_phone) {
      this.contact_phone_list = supplier.contact_phone.split(";");
      this.contact_phone_one = this.contact_phone_list[0];
    }
    supplier.tags_array = supplier.tag_list.split(",").filter((tag) => {
      return this.tag_info_array.indexOf(tag) !== -1;
    });
    supplier.rules = this.replaceKeysWithAcronyms(supplier.rules);
    this.levelsWithRequiredRules = {};

    const default_schedule = {
      mon: [["09:00", "18:00"]],
      tue: [["09:00", "18:00"]],
      wed: [["09:00", "18:00"]],
      thu: [["09:00", "18:00"]],
      fri: [["09:00", "18:00"]],
      sat: [["09:00", "18:00"]],
      sun: [["09:00", "18:00"]],
      url: null,
      last_changed_at: null,
    };
    try {
      this.schedule = JSON.parse(supplier.opening_hours_text);
      if (!this.schedule) {
        this.schedule = default_schedule;
      }
    } catch (e) {
      this.schedule = default_schedule;
    }

    this.map = {
      link: yandex_link,
      lat: supplier.lat,
      lng: supplier.lng,
    };

    if (supplier.administrative_info && typeof supplier.administrative_info === "object") {
      let administrative_info_array = [];
      supplier.administrative_info.map((contact) => {
        administrative_info_array.push(`${contact.position}, ${contact.name}, ${contact.email}, ${contact.phone}`);
      });

      supplier.administrative_info = administrative_info_array.join("\n");
    }

    supplier.general_information = typeof supplier.general_information === "string" ? supplier.general_information : "";
    supplier.additional = typeof supplier.additional === "string" ? supplier.additional : "";
    supplier.third_party_validation = "";

    if (Array.isArray(supplier.payment_details)) {
      supplier.payment_details = Vue.observable({});
    }
    this.supplier = supplier;

    const configsList = [{ country: [this.country] }];
    await this.fetchConfigs(configsList);
  },
  methods: {
    ...mapGetters({
      countryConfig: "config/country",
    }),
    ...mapActions({
      fetchConfigs: "config/actionFetchConfigs",
      getSupplier: "suppliers/get_supplier",
      updateSupplier: "suppliers/update_supplier",
      cloneSupplier: "suppliers/clone_supplier",
      openModal: "modal/actionOpenModal",
    }),
    prepareSupplierCopyForCloning() {
      const copiedSupplier = cloneDeep(this.supplier);
      copiedSupplier.scanning_status = "1";
      return copiedSupplier;
    },
    phone_validate(value) {
      return value.replace(this.config.phone_regex, this.config.phone_regex_replace);
    },
    removeItemByKey(list, key) {
      list.splice(key, 1);
    },
    addPhone(value) {
      this.contact_phone_list.push(value);
      this.contact_phone_one = this.config.phone_prefix;
    },
    addHourRange() {
      const new_opening_hours_item = this.$refs.new_opening_hours_item;
      let value = new_opening_hours_item.options[new_opening_hours_item.selectedIndex].value;
      this.schedule[value].push(["09:00", "18:00"]);
    },
    updateCoordinates(location) {
      this.map.lat = location[0];
      this.map.lng = location[1];
    },
    async fireCloningSupplier() {
      try {
        const preparedSupplierCopyForCloning = this.prepareSupplierCopyForCloning();
        await this.cloneSupplier(preparedSupplierCopyForCloning);
        this.openSuccessMessageModal();
      } catch (error) {
        if (error.response.status == 422) {
          this.validationErrors = error.response.data.errors;
        }

        const preparedError = prepareAxiosErrors(error);
        this.openFailMessageModal(preparedError);
      }
    },
    async redirectOnSuppliersList() {
      await this.$router.push({ name: "supplier_list" });
    },
    openSuccessMessageModal() {
      this.openModal({
        type: MODAL_TYPES.message,
        payload: {
          message: "Data saved",
          headerText: "SUCCESS!",
          onAccept: this.redirectOnSuppliersList,
        },
      });
    },
    openFailMessageModal(error) {
      this.openModal({
        type: MODAL_TYPES.message,
        payload: {
          message: error.errorMessage,
          headerText: "Something went wrong...",
        },
      });
    },
    async fireUpdatingSupplier() {
      let supplier = cloneDeep(this.supplier);
      let opening_hours_text = JSON.stringify(this.schedule);
      if (this.isWysiwygElementEmpty) {
        this.$refs.supplierUpdateFormRef.scrollIntoView();
        return;
      }
      if (supplier.opening_hours_text !== opening_hours_text) {
        this.schedule.last_changed_at = new Date();
        opening_hours_text = JSON.stringify(this.schedule);
      }
      supplier.opening_hours_text = opening_hours_text;
      supplier.tag_list = supplier.tags_array.join(",");
      supplier.contact_phone = this.contact_phone_list.join(";");
      supplier.lat = this.map.lat;
      supplier.lng = this.map.lng;
      supplier.rules = this.replaceKeysAcronymsWithBackronyms(supplier.rules);
      try {
        await this.updateSupplier({ id: this.$route.params.supplier, data: supplier });
        this.openSuccessMessageModal();
      } catch (error) {
        if (error.response.status == 422) {
          this.validationErrors = error.response.data.errors;
        }

        const preparedError = prepareAxiosErrors(error);
        this.openFailMessageModal(preparedError);
      }
    },
    clearStringFromHtml(htmlString) {
      const regex = /<\/?[^>]+(>|$)/g;
      return htmlString.replace(regex, "");
    },
    requireRulesForSubscriptionsLevels(levelsWithEmptyRules) {
      this.levelsWithRequiredRules = {};
      levelsWithEmptyRules.forEach((level) => {
        this.levelsWithRequiredRules[level] = true;

        this.levelRulesRerenderKey++;
      });
    },
    replaceKeysWithAcronyms(sourceObj) {
      const acronymKeysObj = {};
      Object.entries(this.config.subscriptions_acronyms).forEach(([key, value]) => {
        acronymKeysObj[value] = sourceObj[key];
      });

      return acronymKeysObj;
    },
    replaceKeysAcronymsWithBackronyms(sourceObj) {
      const bacronymKeysObj = {};
      Object.entries(this.config.subscriptions_acronyms).forEach(([key, value]) => {
        bacronymKeysObj[key] = sourceObj[value];
      });

      return bacronymKeysObj;
    },
  },
};
</script>

<style scoped>
#update_lat_lng {
  width: 100%;
  min-height: 300px;
}

input:invalid {
  border: red solid 1px;
}

textarea:invalid {
  border: red solid 1px;
}

.requiredFieldError {
  border: red solid 1px;
}
</style>
