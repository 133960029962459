<template>
  <div class="select" @blur="onClickOutside" tabindex="0">
    <p v-if="props.label" class="select_label">
      <span>{{ props.label }}</span>
      <Tooltip :text="props.tooltipText" :right="props.tooltipTextOnTheRight" />
      <Tooltip v-if="props.required" text="Required field" color="#e3001b" :right="props.tooltipTextOnTheRight" />
    </p>
    <div class="select_body" :class="selectClass">
      <div @click.prevent="onToggleSelect" class="select_placeholder">
        <span :title="selectedValue">{{ selectedValue }}</span>
        <Icon v-if="!isDisabled" :icon="selectIcon" class="select_icon" />
      </div>

      <SpinnerRadial v-if="isProcessing" class="select_spinner" />

      <div v-show="isFocus" class="select_options" :style="selectOptionsStyle">
        <template v-if="props.multipleChoices">
          <BaseCheckbox
            v-for="(option, key) in options"
            :key="getOptionKey(option.text)"
            class="select_checkable-option"
            :class="{ selected_option: option.value }"
            :value="option.value"
            text-overflow-elipsis
            @change="onMultipleChange($event, key)"
          >
            <slot name="option" :option="option">
              <span :title="option.text">{{ option.text }}</span>
            </slot>
          </BaseCheckbox>
        </template>
        <template v-else>
          <div
            v-for="option in options"
            :key="getOptionKey(option.text)"
            @click="onSelect(option.value, option.text)"
            class="select_option"
            :class="getOptionClass(option.text)"
          >
            <slot name="option" :option="option">
              <span :title="option.text">{{ option.text }}</span>
            </slot>
          </div>
        </template>
      </div>
    </div>

    <span v-if="errorMessage" class="select_error" :title="errorMessage" data-error-type="message">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import { computed, ref, watch } from "@vue/composition-api";

import BaseCheckbox from "./BaseCheckbox.vue";
import Icon from "@/components/icons/Icon.vue";
import SpinnerRadial from "@/components/loaders/SpinnerRadial.vue";
import Tooltip from "@/components/tooltips/Tooltip.vue";

import { ERRORS_MESSAGES } from "@/helpers/validators";
import isObject from "lodash/isObject";
import { t } from "@/helpers/i18n";

const OPTION_HEIGHT = 44;
const BOTTOM_AND_TOP_BORDERS_SUMMARY = 2;

export default {
  components: {
    BaseCheckbox,
    Icon,
    SpinnerRadial,
    Tooltip,
  },
  emits: ["select", "update:modelValue"],

  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },

  props: {
    error: {
      type: [String, null],
      default: null,
    },
    options: {
      type: [Object, Array, null],
      default: null,
      validator: function (value) {
        if (value === null) {
          return true;
        }

        return isObject(value) || Array.isArray(value);
      },
    },
    placeholder: {
      type: [String, null],
      default: null,
    },
    permanentPlaceholder: {
      type: Boolean,
      default: false,
    },
    multipleChoices: {
      type: Boolean,
      default: false,
    },
    label: {
      type: [String, null],
      default: null,
    },
    icon: {
      type: [String, null],
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, null],
      default: null,
    },
    maxOptionsVisible: {
      type: Number,
      default: 6,
    },
    processing: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      required: false,
    },
    tooltipTextOnTheRight: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const isFocus = ref(false);
    const isChanged = ref(false);

    function getSelectedTextByValue() {
      if (Array.isArray(props.options)) {
        return props.options.find((option) => option.value === props.modelValue)?.text;
      }

      return;
    }

    const selectedValue = computed({
      get() {
        return getSelectedTextByValue() || props.placeholder;
      },
      set(val) {
        isChanged.value = true;
        emit("update:modelValue", val);
      },
    });

    const isProcessing = computed(() => props.processing);
    const isDisabled = computed(() => props.disabled || props.processing);

    const errorMessage = computed(() => {
      return isChanged.value ? false : t(ERRORS_MESSAGES[props.error]) || props.error;
    });

    const selectFocusStatusIcon = computed(() => (isFocus.value ? "arrow-up" : "arrow-down"));
    const selectIcon = computed(() => props.icon || selectFocusStatusIcon.value);

    const selectClass = computed(() => ({
      focus: isFocus.value,
      error: errorMessage.value,
      disabled: isDisabled.value,
      processing: isProcessing.value,
    }));

    const selectOptionsStyle = computed(() => ({
      "max-height": `${props.maxOptionsVisible * OPTION_HEIGHT + BOTTOM_AND_TOP_BORDERS_SUMMARY}px`,
    }));
    function getOptionKey(option) {
      if (option.text === null) {
        return "nullish";
      }
      return option.text;
    }

    function onToggleSelect() {
      if (!isDisabled.value) {
        isFocus.value = !isFocus.value;
      }
    }

    function onMultipleChange(checked, optionKey) {
      isChanged.value = true;
      emit("select", optionKey, checked);
    }

    function onSelect(optionValue, optionText) {
      selectedValue.value = props.permanentPlaceholder ? selectedValue.value : optionText;
      emit("update:modelValue", optionValue);
      emit("select", optionValue);
      onToggleSelect();
    }

    function onClickOutside() {
      isFocus.value = false;
    }

    function getOptionClass(optionText) {
      return { selected: selectedValue.value === optionText };
    }

    watch(
      () => props.error,
      () => {
        isChanged.value = false;
      }
    );

    return {
      props,
      selectClass,
      selectedValue,
      isFocus,
      errorMessage,
      selectIcon,
      isDisabled,
      isProcessing,
      selectOptionsStyle,

      onMultipleChange,
      onSelect,
      getOptionKey,
      onToggleSelect,
      onClickOutside,
      getOptionClass,
    };
  },
};
</script>

<style lang="scss" scoped>
$input-height: 38px;
$option-height: 44px;
$input-border-width: 1px;

.select {
  position: relative;
  padding-bottom: 24px;

  &_label {
    display: flex;
    gap: 6px;
    font-size: 18px;
    font-weight: 500;
    color: #444545;
    margin-bottom: 6px;
  }

  &_body {
    position: relative;
    width: 100%;
    border: $input-border-width solid #444545;
    border-radius: 8px;
    height: $input-height;
    color: #444545;
    font-weight: 500;
    font-size: 16px;
    background-color: #fafafa;
    cursor: pointer;

    &:hover,
    &.focus {
      background-color: #9c9c9c;
      .select_placeholder {
        color: white;
      }

      svg {
        fill: white;
      }
    }

    &.focus {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.error {
      border-color: red;
    }

    &.disabled {
      opacity: 0.5;

      &:hover {
        background-color: #fafafa;
        cursor: auto;

        .select_placeholder {
          color: #444545;
        }

        svg {
          fill: black;
        }
      }
    }
  }

  &_placeholder {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 0 18px 0 10px;

    & > span {
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  &_icon {
    position: absolute;
    right: 8px;
  }

  &_options {
    position: absolute;
    overflow-y: auto;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    background-color: white;
    border: $input-border-width solid #444545;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  &_checkable-option,
  &_option {
    padding: 0 10px;
    height: $option-height;
    width: 100%;
    cursor: pointer;

    &:hover {
      background-color: #fafafa;
    }

    &:first-child {
      border-top: $input-border-width solid #e7e7e7;
    }

    &:not(:last-child) {
      border-bottom: $input-border-width solid #e7e7e7;
    }

    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &_option {
    display: flex;
    align-items: center;

    &.selected {
      background-color: #9c9c9c;
      color: white;
    }

    & > span {
      flex: 1;
      white-space: nowrap;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  &_spinner {
    position: absolute;
    top: 19px;
    right: 0;
    transform: translate(-50%, -50%);
  }

  &_error {
    font-size: 13px;
    color: #e3001b;
    line-height: 24px;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    max-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}
</style>
