<template>
  <small-layout
    ><div>
      <h1>Create</h1>
      <validation-errors :errors="validationErrors"></validation-errors>
      <form @submit.prevent="createSupplier">
        <fieldset>
          <legend>информация об Спортивном Объекте для опубликования</legend>
          <div>
            <label for="supplier_name">Название:</label>
            <input
              id="supplier_name"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.name"
              required
            />
          </div>
          <div>
            <label for="general_information">О нас (для клиента)</label>
            <wysiwyg v-model="supplier.general_information"></wysiwyg>
          </div>
          <div v-for="subscription in config.subscriptions">
            <label :for="'rules_' + subscription">Rules {{ subscription }}</label>
            <textarea
              rows="4"
              cols="50"
              v-model="supplier.rules[subscription]"
              :id="'rules_' + subscription"
            ></textarea>
          </div>
          <div>
            <label for="city">City</label>
            <select v-model="supplier.city" id="city">
              <option value=""></option>
              <option v-for="city in config.cities" :value="city" :key="city">{{ city }}</option>
            </select>
          </div>
          <div>
            <label for="address">Адрес</label>
            <textarea rows="4" cols="50" v-model="supplier.address" id="address"></textarea>
          </div>
          <div>
            <label for="update_lat_lng">На карте</label>
            <YandexMap
              v-if="map.link"
              id="update_lat_lng"
              v-bind:link="map.link"
              v-bind:lat="map.lat"
              v-bind:lng="map.lng"
              v-on:update_coordinates="updateCoordinates"
            />
          </div>
          <div>
            <label for="contact_phone">Phones for Holders</label>

            <ul id="contact_phone_block">
              <li>
                <phone-input
                  id="contact_phone"
                  :value="contact_phone_one"
                  :pattern="config.phone_input_regex"
                  @change="addPhone"
                ></phone-input>
              </li>
              <li v-for="(item, key) in contact_phone_list">
                {{ item | phone_validate }}
                <span><button @click.prevent="removeItemByKey(contact_phone_list, key)">X</button></span>
              </li>
            </ul>
          </div>
          <div>
            <label for="website">Website</label>
            <input
              type="text"
              inputmode="url"
              pattern="https?://.*"
              maxlength="191"
              style="width: 100%"
              v-model="supplier.website"
              id="website"
            />
          </div>
          <div>
            <label for="client_rules">Rules</label>
            <input
              type="text"
              inputmode="url"
              pattern="https?://.*"
              maxlength="191"
              style="width: 100%"
              v-model="supplier.client_rules"
              id="client_rules"
            />
          </div>
          <div>
            <label for="opening_hours_text">Schedule</label>
            <ul id="opening_hours_text">
              <li>
                <label for="schedule_url">Path to schedule</label>
                <input
                  type="text"
                  inputmode="url"
                  pattern="https?://.*"
                  maxlength="191"
                  style="width: 100%"
                  v-model="schedule.url"
                  id="schedule_url"
                />
                <label for="schedule_message">Warning about schedule</label>
                <input
                  type="text"
                  inputmode="text"
                  maxlength="191"
                  style="width: 100%"
                  v-model="schedule.message"
                  id="schedule_message"
                />
                <label for="schedule_last_changed_at">Last sync</label>
                <input type="datetime-local" v-model="schedule.last_changed_at" id="schedule_last_changed_at" />
              </li>
              <li>
                <label for="new_opening_hours_item">New period</label>
                <select id="new_opening_hours_item" ref="new_opening_hours_item">
                  <option value="mon">Пн</option>
                  <option value="tue">Вт</option>
                  <option value="wed">Ср</option>
                  <option value="thu">Чт</option>
                  <option value="fri">Пт</option>
                  <option value="sat">Сб</option>
                  <option value="sun">Вс</option>
                </select>
                <button @click.prevent="addHourRange">Add</button>
              </li>
              <li>
                Пн
                <span v-for="(item, key) in schedule.mon">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.mon, key)">X</button></span>
                </span>
              </li>
              <li>
                Вт
                <span v-for="(item, key) in schedule.tue">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.tue, key)">X</button></span>
                </span>
              </li>
              <li>
                Ср
                <span v-for="(item, key) in schedule.wed">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.wed, key)">X</button></span>
                </span>
              </li>
              <li>
                Чт
                <span v-for="(item, key) in schedule.thu">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.thu, key)">X</button></span>
                </span>
              </li>
              <li>
                Пт
                <span v-for="(item, key) in schedule.fri">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.fri, key)">X</button></span>
                </span>
              </li>
              <li>
                Сб
                <span v-for="(item, key) in schedule.sat">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.sat, key)">X</button></span>
                </span>
              </li>
              <li>
                Вс
                <span v-for="(item, key) in schedule.sun">
                  <input type="time" v-model="item[0]" />-<input type="time" v-model="item[1]" />
                  <span v-if="key != 0"><button @click.prevent="removeItemByKey(schedule.sun, key)">X</button></span>
                </span>
              </li>
            </ul>
          </div>
          <div>
            <label>Теги (для карты)</label>
            <div v-for="(title, index) in tag_info_array">
              <label :for="'supplier_tags_' + index">{{ title }}</label>
              <input
                type="checkbox"
                :id="'supplier_tags_' + index"
                v-bind:value="title"
                v-model="supplier.tags_array"
              />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Details Act of services</legend>
          <div>
            <label for="unp">{{ $t("UNP (Payer Registration Number)") }}</label>
            <input type="number" v-model="supplier.unp" id="unp" />
          </div>
          <div v-if="supplier.third_party_validation === 'off'">
            <label for="legal_name">Legal Name</label>
            <input type="text" v-model="supplier.registered_name" id="legal_name" />
          </div>
          <div>
            <label for="number_agreement">Номер и дата соглашения</label>
            <input
              id="number_agreement"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.number_agreement"
            />
          </div>
          <div>
            <label for="number_agreement_super">Номер и дата соглашения (SUPER)</label>
            <input
              id="number_agreement_super"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.number_agreement_super"
            />
          </div>
          <div>
            <label for="legal_address">Benefiiary Legal Address</label>
            <input
              id="legal_address"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.legal_address"
            />
          </div>
          <div>
            <label for="aemail">Benefiiary Accountant Email</label>
            <input
              id="aemail"
              style="width: 100%"
              type="email"
              inputmode="email"
              multiple="multiple"
              maxlength="191"
              v-model="supplier.aemail"
            />
          </div>
          <div>
            <label for="postaddress">Benefiiary Post Address</label>
            <input id="postaddress" style="width: 100%" type="text" maxlength="191" v-model="supplier.postaddress" />
          </div>
          <div>
            <label for="legal_contact_phone">Benefiiary Legal Contact Phone</label>
            <phone-input
              id="legal_contact_phone"
              style="width: 100%"
              :value="supplier.legal_contact_phone"
              @change="supplier.legal_contact_phone = arguments[0]"
              :pattern="config.phone_input_regex"
            ></phone-input>
          </div>
          <div>
            <label for="bank_name">Benefiiary Bank</label>
            <input id="bank_name" style="width: 100%" type="text" maxlength="75" v-model="supplier.bank_name" />
          </div>
          <div>
            <label for="bik">Benefiiary BIK</label>
            <input id="bik" style="width: 100%" type="text" maxlength="11" v-model="supplier.bik" />
          </div>
          <div>
            <label for="accnum">Benefiiary Account</label>
            <input id="accnum" style="width: 100%" type="text" maxlength="75" v-model="supplier.accnum" />
          </div>
          <div v-for="(value, name) in config.payment_fields">
            <label :for="name">{{ $t(`Title ${name}`) }} {{ supplier.payment_details[name] }}</label>
            <input :id="name" v-model="supplier.payment_details[name]" style="width: 100%" type="text" maxlength="75" />
          </div>
          <div>
            <label for="vat_rate">VAT rate</label>
            <select id="vat_rate" v-model="supplier.vat_rate">
              <option v-for="(value, name) in config.vat_rate" :key="name" :value="value">{{ name }}</option>
            </select>
          </div>
          <div>
            <label for="sellStrategy">Sell strategy</label>
            <select v-model="supplier.sell_strategy" id="sellStrategy">
              <option value="" selected hidden>Select strategy</option>
              <option v-for="option in sellStrategyOptions" :value="option.value" :key="option.value">
                {{ option.text }}
              </option>
            </select>
          </div>
        </fieldset>
        <fieldset>
          <legend>Other info in agreement</legend>
          <div>
            <label for="appendix">Номер и дата приложения 1</label>
            <input id="appendix" style="width: 100%" type="text" maxlength="191" v-model="supplier.appendix" />
          </div>
          <div>
            <label for="official_representative">От лица кого подписано? от лица "директора/руководителя/..."</label>
            <input
              id="official_representative"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.official_representative"
            />
          </div>
          <div>
            <label for="power_of_attorney_representative">на каком Основании Исполнитель заключает договор</label>
            <input
              id="power_of_attorney_representative"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.power_of_attorney_representative"
            />
          </div>
          <div>
            <label for="hardware_device">Переданное устройство</label>
            <input
              id="hardware_device"
              style="width: 100%"
              type="text"
              maxlength="191"
              v-model="supplier.hardware_device"
            />
          </div>
          <div>
            <label for="hardware_device_number">Номер телефона устройства (*147#)</label>
            <phone-input
              id="hardware_device_number"
              :value="supplier.hardware_device_number"
              @change="supplier.hardware_device_number = arguments[0]"
              :pattern="config.phone_input_regex"
            ></phone-input>
          </div>
        </fieldset>
        <fieldset>
          <legend>важные данные и административная информация</legend>
          <div>
            <label for="administrative_info">Contacts (Every line: Position, Name, email/phone)</label>
            <textarea rows="4" cols="50" v-model="supplier.administrative_info" id="administrative_info"></textarea>
          </div>
          <div>
            <label for="negotiation">Согласованные моменты, особенность договора с ними, помимо цен</label>
            <textarea rows="4" cols="50" v-model="supplier.negotiation" id="negotiation"></textarea>
          </div>
          <div>
            <label for="partner_status">Текущий статус партнера</label>
            <textarea rows="4" cols="50" v-model="supplier.partner_status" id="partner_status"></textarea>
          </div>
          <div>
            <label for="reason_outdated">Какую информацию надо добавить</label>
            <textarea rows="4" cols="50" v-model="supplier.reason_outdated" id="reason_outdated"></textarea>
          </div>
          <div>
            <label for="allsports_contact">кто договаривался</label>
            <textarea rows="4" cols="50" v-model="supplier.allsports_contact" id="allsports_contact"></textarea>
          </div>
          <div>
            <label for="support_phone">Поддержка зала:</label>
            <input list="support_phonees" v-model="supplier.support_phone" id="support_phone" />
            <datalist id="support_phonees">
              <option :value="config.support_phone">Телефон по умолчанию</option>
            </datalist>
          </div>
          <div>
            <label for="administration_status">Актуальность Информации</label>
            <input list="administration_statuses" v-model="supplier.administration_status" id="administration_status" />
            <datalist id="administration_statuses">
              <option value="Договор на подписании" />
              <option value="Цены на согласовании" />
              <option value="Требуется установить устройство" />
              <option value="Контрольная закупка" />
              <option value="Данные требуется обновить" />
              <option value="Правила на заверении" />
              <option value="Акт приема передачи на подписании" />
              <option value="Требуется забрать документы" />
              <option value="Требуется записать телефон переданного устройства" />
            </datalist>
          </div>
          <div>
            <label for="access_mode">Access Mode (standard, ticket like GymExpress, location required)</label>
            <select v-model="supplier.access_mode" id="access_mode">
              <option value="standard">Standard</option>
              <option value="ticket">Ticket</option>
              <option value="location">Location</option>
              <option value="akova">Akova</option>
            </select>
          </div>
          <div>
            <label for="scanning_status"></label>
            <select v-model="supplier.scanning_status" id="scanning_status">
              <option value="0">Незаполненный</option>
              <option value="1">Заполненный</option>
              <option value="5">Офлайн (QR only - should not be by default)</option>
              <option value="13">Офлайн (NFC only - QR OFF)</option>
            </select>
          </div>
          <div>
            <label for="third_party_validation">Turn off validation UNN/IBAN</label>
            <input
              type="checkbox"
              id="third_party_validation"
              v-model="supplier.third_party_validation"
              :true-value="'off'"
            />
          </div>
          <div>
            <label for="visit_autoaccept">Auto-confirmation of visits (for facilities without administrators)</label>
            <input
              type="checkbox"
              id="visit_autoaccept"
              v-model="supplier.visit_autoaccept"
            />
          </div>
          <br>
          <div>
            <button>Сохранить</button>
          </div>
        </fieldset>
      </form>
    </div></small-layout
  >
</template>

<script>
import ValidationErrors from "../../ValidationErrors";
import PhoneInput from "../../PhoneInput";
import YandexMap from "../../YandexMap.vue";
import PdfReader from "../../PdfReader";
import Vue from "vue";
import axios from "axios";
import config from "../../../config";
import { mapActions, mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
const localConfig = config.supported_countries.by;
const country = "by";

const yandex_link = process.env.VUE_APP_YANDEX_LINK;

export default {
  components: {
    ValidationErrors,
    YandexMap,
    PdfReader,
    PhoneInput,
  },
  data() {
    const rules = {};
    localConfig.subscriptions.map((subscription) => (rules[subscription] = ""));
    return {
      contact_phone_one: localConfig.phone_zeroes,
      map: {
        link: yandex_link,
        lat: localConfig.lat,
        lng: localConfig.lng,
      },
      config: localConfig,
      tag_info_array: [
        "Бесплатная парковка",
        "Доплата на объекте",
        "Круглосуточный",
        "Только для женщин",
        "Услуги для родителей с детьми или беременных женщин",
        "Сезонное обслуживание",
        "Wi-Fi",
        "Требуется бронирование",
      ],
      supplier: {
        aggregator: localConfig.aggregator,
        country,
        city: localConfig.cities[0],
        tags_array: [],
        vat_rate: "no_vat",
        sell_strategy: "by3",
        payment_details: Vue.observable({}),
        lat: localConfig.lat,
        lng: localConfig.lng,
        rules,
        general_information: "",
        legal_contact_phone: localConfig.phone_zeroes,
        hardware_device_number: localConfig.phone_zeroes,
        additional: "",
        third_party_validation: localConfig.unn_validation_url ? "" : "off",
        unn_validation_url: localConfig.unn_validation_url,
        access_mode: "standard",
        visit_autoaccept: false,
      },
      validationErrors: "",
      contact_phone_list: [],
      schedule: {
        mon: [["09:00", "18:00"]],
        tue: [["09:00", "18:00"]],
        wed: [["09:00", "18:00"]],
        thu: [["09:00", "18:00"]],
        fri: [["09:00", "18:00"]],
        sat: [["09:00", "18:00"]],
        sun: [["09:00", "18:00"]],
        url: null,
        last_changed_at: null,
      },
    };
  },
  filters: {
    phone_validate(value) {
      return value.replace(localConfig.phone_regex, localConfig.phone_regex_replace);
    },
  },
  methods: {
    ...mapGetters({
      countryConfig: "config/country",
    }),
    ...mapActions({
      fetchConfigs: "config/actionFetchConfigs",
    }),
    removeItemByKey(list, key) {
      list.splice(key, 1);
    },
    addPhone(value) {
      this.contact_phone_list.push(value);
      this.contact_phone_one = localConfig.phone_prefix;
    },
    addHourRange() {
      const new_opening_hours_item = this.$refs.new_opening_hours_item;
      let value = new_opening_hours_item.options[new_opening_hours_item.selectedIndex].value;
      this.schedule[value].push(["09:00", "18:00"]);
    },
    updateCoordinates(location) {
      this.map.lat = location[0];
      this.map.lng = location[1];
    },
    createSupplier() {
      let supplier = cloneDeep(this.supplier);
      this.schedule.last_changed_at = new Date();
      supplier.opening_hours_text = JSON.stringify(this.schedule);
      supplier.tag_list = supplier.tags_array.join(",");
      supplier.contact_phone = this.contact_phone_list.join(";");
      supplier.lat = this.map.lat;
      supplier.lng = this.map.lng;
      axios
        .post(`/api/suppliers`, supplier)
        .then((response) => {
          this.$router.push({ name: "supplier_list" });
        })
        .catch((error) => {
          if (error.response.status == 422) {
            this.validationErrors = error.response.data.errors;
          }
        });
    },
  },
  computed: {
    sellStrategyOptions: function () {
      const optionsList = this.countryConfig()?.sellers.map(({ code, name }) => ({ value: code, text: name }));
      const supplementedOptionsList = [{ value: "country", text: "Country" }, ...optionsList];

      return supplementedOptionsList;
    },
  },
  async mounted() {
    const configsList = [{ country: [this.supplier.country] }];
    await this.fetchConfigs(configsList);
  },
};
</script>

<style scoped>
#update_lat_lng {
  width: 100%;
  min-height: 300px;
}

input:invalid {
  border: red solid 1px;
}
</style>
